import React from 'react';

import ky from 'ky';
import axios from 'axios';
import apiClient from "../services/api";

const Login = (props) => {

    const [email, setEmail] = React.useState('');

    const [password, setPassword] = React.useState('');

    const handleSubmit = (e) => {

        e.preventDefault();
        apiClient.get('/sanctum/csrf-cookie')

            .then(response => {

                apiClient.post('/login', {

                    email: email,

                    password: password

                }).then(response => {

                    console.log(response)

                    if (response.status === 200) {
                        props.login();
                    }

                })

            });
       /* ky.get('http://imagegen-v2-api-service.test/sanctum/csrf-cookie', {
            credentials: 'include',
            mode: 'cors',
        }).then(response => {
            window.csrftoken = response.headers.get('XSRF-TOKEN')['value'];
            console.log("CSRF TOKEN:");
            console.log(window.csrftoken);
            ky.post('http://imagegen-v2-api-service.test/login', {
                json: {
                    email: email,
                    password: password
                },
                credentials: 'include',
                mode: 'cors',
                hooks: {
                    beforeRequest : [
                        (options) => {
                            options.headers.set('X-Requested-With', 'XMLHttpRequest');
                            options.headers.set('X-CSRF-TOKEN', window.csrftoken);
                        }
                    ]
                }
            }).json().then(response => {
                console.log(response);
            });
        })*/



    }

    return (

        <div>

            <h1>Login</h1>

            <form onSubmit={handleSubmit}>

                <input

                    type="email"

                    name="email"

                    placeholder="Email"

                    value={email}

                    onChange={e => setEmail(e.target.value)}

                    required

                />

                <input

                    type="password"

                    name="password"

                    placeholder="Password"

                    value={password}

                    onChange={e => setPassword(e.target.value)}

                    required

                />

                <button type="submit">Login</button>

            </form>

        </div>

    );

}

export default Login;