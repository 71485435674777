import axios from 'axios';

const apiClient = axios.create({

    baseURL: 'https://imagegen-v2-api.humancentric.technology/',

    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    }

});

export default apiClient;