import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import Login from './components/Login'
import ImageGenerator from "./ImageGenerator";
import apiClient from "./services/api";

const App = () => {

    const [loggedIn, setLoggedIn] = React.useState(
        sessionStorage.getItem('loggedIn') == 'true' || false
    )

    const login = () => {
        setLoggedIn(true);
        sessionStorage.setItem('loggedIn', 'true');
    }

    const logout = () => {
        apiClient.post('/logout').then((response: { status: number; }) => {
            if (response.status === 204 || response.status === 200) {
                setLoggedIn(false);
                sessionStorage.setItem('loggedIn', 'false');
            }
        })
    }

    const authLink = loggedIn
        ? <button onClick={logout}>Logout</button>
        : <NavLink to={'/login'}>Login</NavLink>

    return (
        <div className={"App"}>
            <header className="App-header">
                <h1> AI4 Promo Image Generator</h1>
            </header>
            <Router>

                <div className={"App-navlink-container"}>
                    {authLink}
                    <NavLink to='/image-generator'>Image Generator</NavLink>

                </div>

                <Switch>
                    <Route path='/login'render={props => (
                        <Login {...props} login={login} />
                    )} />
                    <Route path='/image-generator' render={props => (
                        <ImageGenerator {...props} loggedIn={loggedIn} />
                    )} />

                </Switch>

            </Router>
        </div>


    );

};

export default App;
