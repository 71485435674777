import React, {FunctionComponent} from 'react';
import {image} from "html2canvas/dist/types/css/types/image";

import apiClient from "./services/api";
import formData from 'form-data';
import {v4 as uuidv4 } from 'uuid';
const InputForm: FunctionComponent<{
    imageConfigs: any,
    currentImageConfig: any,
    setCurrentImageConfig: any,
    brandName: string,
    setBrandName: any,
    dateString: string,
    setDateString: any,
    //timeString: string,
    //setTimeString: any,
    talkTitle: string,
    setTalkTitle: any,
    speakerName: string,
    setSpeakerName: any,
    speakerTitle: string,
    setSpeakerTitle: any,
    companyName: string,
    speakerImage: string,
    setSpeakerImage: any,
    conferenceTitle: string,
    setConferenceTitle: any,
    speakerCompanyName: string,
    setSpeakerCompanyName: any,
    generatePreviewImage: any,
    setCompanyName: any,
    customStyles: any,
    customStylesObjects: any,
    updateCustomStyles: any,
    backgroundImageString: any,
    setBackgroundImageString: any,
    backgroundImageArray: any,
    loadImageConfigsFromServer: any,
    brandNameFontSize: any,
    brandNamePaddingTop: any,
    brandNameWeight: any,
    dateTimeFontSize: any,
    dateTimeBottomDistance: any,
    dateTimeWeight: any,
    talkTitleFontSize: any,
    talkTitleTopMargin: any,
    talkTitleWeight: any,
    talkTitleWidth: any,
    speakerNameFontSize: any,
    speakerNameTopDistance: any,
    speakerNameWeight: any,
    speakerTitleFontSize: any,
    speakerTitleTopDistance: any,
    speakerTitleLeftPadding: any,
    speakerTitleWeight: any,
    speakerCompanyNameFontSize: any,
    speakerCompanyNameTopDistance: any,
    speakerCompanyNameLeftPadding: any,
    speakerCompanyNameWeight: any,
    imageWidth: any,
    imageHeight: any,
    imageMarginRight: any,
    imageMarginTop: any,
}> = (
        {
            imageConfigs,
            currentImageConfig,
            setCurrentImageConfig,
            brandName,
            setBrandName,
            dateString,
            setDateString,
           // timeString,
           // setTimeString,
            talkTitle,
            setTalkTitle,
            speakerName,
            setSpeakerName,
            speakerTitle,
            setSpeakerTitle,
            companyName,
            conferenceTitle,
            setConferenceTitle,
            customStyles: any,
            setCompanyName,
            speakerImage,
            setSpeakerImage,
            speakerCompanyName,
            setSpeakerCompanyName,
            generatePreviewImage,
            customStylesObjects,
            updateCustomStyles,
            backgroundImageString,
            setBackgroundImageString,
            backgroundImageArray,
            loadImageConfigsFromServer,
            brandNameFontSize,
            brandNamePaddingTop,
            brandNameWeight,
            dateTimeFontSize,
            dateTimeBottomDistance,
            dateTimeWeight,
            talkTitleFontSize,
            talkTitleTopMargin,
            talkTitleWeight,
            talkTitleWidth,
            speakerNameFontSize,
            speakerNameTopDistance,
            speakerNameWeight,
            speakerTitleFontSize,
            speakerTitleTopDistance,
            speakerTitleLeftPadding,
            speakerTitleWeight,
            speakerCompanyNameFontSize,
            speakerCompanyNameTopDistance,
            speakerCompanyNameLeftPadding,
            speakerCompanyNameWeight,
            imageWidth,
            imageHeight,
            imageMarginRight,
            imageMarginTop,
        }) => {

    function createWeightOptions(value: any, setter: Function) {
        let weights = [
            '100', '300', '400', '500', '700', '800', '900'
        ]
        return (
            <select value={value} onChange={e => setter(e.target.value)} >
                {weights.map(function(val: any, index: any) {
                    return (
                        <option key={val} value={val}> {val}</option>
                    )
                })}
            </select>
        )
    }
    const [downloadedImage, setDownloadedImage] = React.useState(new Image);
    const [currentConfigId, setCurrentConfigId] = React.useState(null);
    const [currentConfigSpeakerName, setCurrentConfigSpeakerName] = React.useState(null);
    const [currentConfigSpeakerCompanyName, setCurrentConfigSpeakerCompanyName] = React.useState(null);
    const [currentConfigBrandName, setCurrentConfigBrandName] = React.useState(null);
    const [currentConfigBackgroundImage, setCurrentConfigBackgroundImage] = React.useState(null);
    const [currentConfigTalkDate, setCurrentConfigTalkDate] = React.useState(null);

    function imageReceived() {
        console.log("Image has loaded!");
        let tempCanvas = document.createElement('canvas');
        let context = tempCanvas.getContext('2d');

        tempCanvas.width = downloadedImage.width;
        tempCanvas.height = downloadedImage.height;

        // Ignore ts warning that context may not exist yet - we just got it.
        // @ts-ignore
        context.drawImage(downloadedImage, 0, 0);
        document.appendChild(tempCanvas);

        try {
            localStorage.setItem("saved-image", tempCanvas.toDataURL("image/png"));
            console.log("Finally about to set local speaker image");
            setSpeakerImage(localStorage.getItem("saved-image"));
            generatePreviewImage();
        }
        catch(err) {
            console.log("Error: " + err);
            alert("Error loading saved image - please contact administrator.");
        }

    }
    function getRemoteImageAsLocal(imageUrl: string) {
        console.log("In getRemoteImageAsLocal");
        // loading a remote image directly creates
        // cors issues with the canvas;
        // to mitigate use localstroage
        // (see https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image)



        apiClient.get(imageUrl, {
            responseType: 'arraybuffer'
        })
            .then(response => {
                //let imageObject = new Image;
                //imageObject.crossOrigin = "anonymous";
                //console.log("About to add event listener")
                //imageObject.addEventListener('load', imageReceived, false);
                let base64Image = Buffer.from(response.data, 'binary').toString('base64');
                setSpeakerImage('data:;base64,'+base64Image);
                //setDownloadedImage(imageObject);
                }

            ).catch(error => alert("Network error encountered, if this persists please contact administrator and provide the following message: " + error));

    }

    function handleNewConfig(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        setCurrentConfigId(null);
        window.location.reload();
    }
    function handleConfigSaving(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        let shouldForceCreatingNewConfig = false;
        // if target button has id "save as new"
            // set shouldSaveAsNew = true
        const element = e.currentTarget as HTMLButtonElement;
        if (currentConfigId === null) {
            shouldForceCreatingNewConfig = true;
        }
        else if (currentConfigSpeakerName !== speakerName || currentConfigBrandName !== brandName || currentConfigSpeakerCompanyName !== speakerCompanyName || currentConfigTalkDate !== dateString || currentConfigBackgroundImage !== backgroundImageString) {
            // ask if should overwrite or not set flags as appropriate
            // if they say don't overwrite, set shouldSaveAsNew = true
            let userDidConfirmNewConfig = window.confirm("It appears that the speaker name, brand name, company name, talk date, or background image has changed. Do you want to save a new image configuration? \n Please press 'OK' so save a new config or 'Cancel' to overwrite the existing config.")
            if (userDidConfirmNewConfig) {
                shouldForceCreatingNewConfig = true;
            }
        }
        // get image as something we're ready to send in files
        let imageFile = speakerImage;
        let configFormData = new FormData(); //
        console.log(imageFile);
        configFormData.append('file', imageFile);
        configFormData.append('background_image', backgroundImageString);
        configFormData.append('brand_name', brandName);
        configFormData.append('conference_title', conferenceTitle);
        configFormData.append('talk_date', dateString);
        configFormData.append('talk_title', talkTitle);
        configFormData.append('speaker_name', speakerName);
        configFormData.append('speaker_title', speakerTitle);
        configFormData.append('speaker_company_name', speakerCompanyName);
        configFormData.append('brand_name_font_size', brandNameFontSize);
        configFormData.append('brand_name_padding_top', brandNamePaddingTop);
        configFormData.append('brand_name_weight', brandNameWeight);
        configFormData.append('date_time_font_size', dateTimeFontSize);
        configFormData.append('date_time_bottom_distance', dateTimeBottomDistance);
        configFormData.append('date_time_weight', dateTimeWeight);
        configFormData.append('talk_title_font_size', talkTitleFontSize);
        configFormData.append('talk_title_top_margin', talkTitleTopMargin);
        configFormData.append('talk_title_weight', talkTitleWeight);
        configFormData.append('talk_title_width', talkTitleWidth);
        configFormData.append('speaker_name_font_size', speakerNameFontSize);
        configFormData.append('speaker_name_top_distance', speakerNameTopDistance);
        configFormData.append('speaker_name_weight', speakerNameWeight);
        configFormData.append('speaker_title_font_size', speakerTitleFontSize);
        configFormData.append('speaker_title_top_distance', speakerTitleTopDistance);
        configFormData.append('speaker_title_left_padding', speakerTitleLeftPadding);
        configFormData.append('speaker_title_weight', speakerTitleWeight);
        configFormData.append('speaker_company_name_font_size', speakerCompanyNameFontSize);
        configFormData.append('speaker_company_name_top_distance', speakerCompanyNameTopDistance);
        configFormData.append('speaker_company_name_left_padding', speakerCompanyNameLeftPadding);
        configFormData.append('speaker_company_name_weight', speakerCompanyNameWeight);
        configFormData.append('image_width', imageWidth);
        configFormData.append('image_height', imageHeight);
        configFormData.append('image_margin_top', imageMarginTop);
        configFormData.append('image_margin_right', imageMarginRight);

        // if currentConfigId is not null, send a post request to create a new config and store the ID as currentConfigId
        if (currentConfigId === null || shouldForceCreatingNewConfig === true) {
            apiClient.post('/api/image-configs', configFormData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then(response => {
                console.log("Saving image config response");
                console.log(response);
                setCurrentConfigId(response['data']['id']);
                setCurrentConfigBrandName(response['data']['brand_name']);
                setCurrentConfigSpeakerCompanyName(response['data']['speaker_company_name']);
                setCurrentConfigTalkDate(response['data']['talk_date']);
                setCurrentConfigBackgroundImage(response['data']['background_image']);
                setCurrentConfigSpeakerName(response['data']['speaker_name']);
                loadImageConfigsFromServer();
            }).catch(error => alert("Network error encountered, if this persists please contact administrator and provide the following message: " + error));

        }
        // otherwise, send a PUT request to update the existing config
        else {

            // laravel cannot handle put requeests of form data, so as per
            // https://stackoverflow.com/questions/50691938/patch-and-put-request-does-not-working-with-form-data we
            // send a post requeset and tell laravel to read as put with hidden _method field
            configFormData.append('_method', 'PUT');
            apiClient.post('/api/image-configs/'+currentConfigId, configFormData, {
                headers: {
                    "Content-Type": "x-www-form-urlencoded"
                }
            }).then(response => {
                console.log("Updating image config response");
                console.log(response);
                setCurrentConfigBrandName(response['data']['brand_name']);
                setCurrentConfigSpeakerCompanyName(response['data']['speaker_company_name']);
                setCurrentConfigTalkDate(response['data']['talk_date']);
                setCurrentConfigBackgroundImage(response['data']['background_image']);
                setCurrentConfigSpeakerName(response['data']['speaker_name']);
                loadImageConfigsFromServer();
            }).catch(error => alert("Network error encountered, if this persists please contact administrator and provide the following message: " + error));
        }
        // update status
    }
    function handleConfigLoading(e: React.MouseEvent<HTMLButtonElement, MouseEvent>){
        console.log("Current config");
        console.log(currentImageConfig);
        console.log(imageConfigs);
        e.preventDefault();
        console.log("About toloadvaluesfrom:");
        //let currentConfigData = imageConfigs.filter((element: any,index: any) => element['id'] === Number(currentImageConfig))[0];
        let currentConfigData = currentImageConfig;
        console.log(currentConfigData);

        // Read the speaker image URL so we can load it into the preview
        //imageFile =

        //setSpeakerImage(currentConfigData['speaker_image']);


        console.log("Old config id: " + currentConfigId);
        console.log("Updating config id to: " + currentConfigData['id']);
        // Set vars used to deicde wehether to save new or update existing image config
        setCurrentConfigId(currentConfigData['id']);
        setCurrentConfigBrandName(currentConfigData['brand_name']);
        setCurrentConfigSpeakerCompanyName(currentConfigData['speaker_company_name']);
        setCurrentConfigTalkDate(currentConfigData['talk_date']);
        setCurrentConfigBackgroundImage(currentConfigData['background_image']);
        setCurrentConfigSpeakerName(currentConfigData['speaker_name']);


        getRemoteImageAsLocal(currentConfigData['speaker_image']);
        setBackgroundImageString(currentConfigData['background_image']);
        setBrandName(currentConfigData['brand_name']);
        setConferenceTitle(currentConfigData['conference_title']);
        setSpeakerCompanyName(currentConfigData['speaker_company_name']);

        setSpeakerName(currentConfigData['speaker_name']);
        setSpeakerTitle(currentConfigData['speaker_title']);
        setDateString(currentConfigData['talk_date']);
        setTalkTitle(currentConfigData['talk_title']);

        // set styles within customSTtyleObject
        console.log("Bout to update custom styles");
        updateCustomStyles('brandNameStyles', 'fontSize', currentConfigData['brand_name_font_size']);
        updateCustomStyles('brandNameStyles', 'paddingTop', currentConfigData['brand_name_font_size']);
        updateCustomStyles('brandNameStyles', 'fontWeight', currentConfigData['brand_name_weight']);

        updateCustomStyles('dateStringStyles', 'fontSize', currentConfigData['date_time_font_size']);
        updateCustomStyles('dateStringStyles', 'bottom', currentConfigData['date_time_bottom_distance']);
        updateCustomStyles('dateStringStyles', 'fontWeight', currentConfigData['date_time_weight']);

        updateCustomStyles('imageContainerStyles', 'width', currentConfigData['image_width']);
        updateCustomStyles('imageContainerStyles', 'height', currentConfigData['image_height']);
        updateCustomStyles('imageContainerStyles', 'top', currentConfigData['image_margin_top']);
        updateCustomStyles('imageContainerStyles', 'right', currentConfigData['image_margin_right']);

        updateCustomStyles('speakerCompanyNameStyles', 'fontSize', currentConfigData['speaker_company_name_font_size']);
        updateCustomStyles('speakerCompanyNameStyles', 'top', currentConfigData['speaker_company_name_top_distance']);
        updateCustomStyles('speakerCompanyNameStyles', 'paddingLeft', currentConfigData['speaker_company_name_left_padding']);
        updateCustomStyles('speakerCompanyNameStyles', 'fontWeight', currentConfigData['speaker_company_name_weight']);

        updateCustomStyles('nameStyles', 'fontSize', currentConfigData['speaker_name_font_size']);
        updateCustomStyles('nameStyles', 'top', currentConfigData['speaker_name_top_distance']);
        updateCustomStyles('nameStyles', 'fontWeight', currentConfigData['speaker_name_weight']);

        updateCustomStyles('speakerTitleStyles', 'fontSize', currentConfigData['speaker_title_font_size']);
        updateCustomStyles('speakerTitleStyles', 'top', currentConfigData['speaker_title_top_distance']);
        updateCustomStyles('speakerTitleStyles', 'fontWeight', currentConfigData['speaker_title_weight']);
        updateCustomStyles('speakerTitleStyles', 'paddingLeft', currentConfigData['speaker_title_left_padding']);

        updateCustomStyles('talkTitleStyles', 'font_size', currentConfigData['talk_title_font_size']);
        updateCustomStyles('talkTitleStyles', 'marginTop', currentConfigData['talk_title_top_margin']);
        updateCustomStyles('talkTitleStyles', 'fontWeight', currentConfigData['talk_title_weight']);
        updateCustomStyles('talkTitleStyles', 'minWidth', currentConfigData['talk_title_width']);


        generatePreviewImage();

        return false;
    }

    function handleBackgroundChange(value: string) {
        let newBrand = '';
        let newConferenceTitle = '';
        let newDates = 'August 17-19, 2021';
        switch (value) {
            case 'url("/Healthcare Summit.png")':
                newBrand = "Ai4"
                newConferenceTitle = "Ai4 2021 Healthcare Summit"
                newDates = "May 5-6, 2021"
                break;
            case 'url("/BTF_2021_CX_v3.jpg")':
                newBrand = "BTF"
                newConferenceTitle = "BTF 2021 CX Summit"
                newDates = "JUNE 9-10, 2021"
                break;
            case 'url("/BTF_2021_Risk_v2.png")':
                newBrand = "BTF"
                newConferenceTitle = "BTF 2021 Risk Summit"
                newDates = "JULY 28-29, 2021"
                break;
            case 'url("/Retail Summit.png")':
                newBrand = "Ai4"
                newConferenceTitle = "Ai4 2021 Retail Summit"
                newDates = "April 7-8, 2021"
                break;
            case 'url("/Ai4 2021.png")':
                newBrand = "Ai4"
                newConferenceTitle = " Ai4 2021"
                newDates = "August 17-19, 2021"
                break;
            case 'url("/RETCON 2021 Office.png")':
                newBrand = "RETCON"
                newConferenceTitle = "RETCON 2021 Office Summit"
                newDates = "March 26-27, 2021"
                break;
            case 'url("/RETCON 2021 Multifamily.png")':
                newBrand = "RETCON"
                newConferenceTitle = "RETCON 2021 Multifamily Summit"
                newDates = "July 14-15, 2021"
                break;
            case 'url("/RETCON 2021 Build.png")':
                newBrand = "RETCON"
                newConferenceTitle = " RETCON 2021 Build Summit"
                newDates = "March 30-31, 2021"
                break;
            default:
                console.log("Invalid background image selected or no default text found");
                setBackgroundImageString(value)

                return
        }
        if (newBrand !== '') {
            setBrandName(newBrand);
        }
        if (newConferenceTitle !== '') {
            setConferenceTitle(newConferenceTitle)
        }

        if (newDates !== '') {
            setDateString(newDates)
        }
        setBackgroundImageString(value)

        imageConfigs.filter((element: any, index: any) => element['background_image'] === value).map(function (val: any, index: any) {
            console.log("IN firstpass loops")
            if (index===0) {
                console.log("Setting current image conifg");
                setCurrentImageConfig(val);
                generatePreviewImage();
            }
        })
    }

    function getConfigDataById(id: Number) {
        let config = imageConfigs.filter((element: any,index: any) => element['id'] === id)[0];
        return config;
    }
    return (

        <form onChange={e => generatePreviewImage()}>
            <label htmlFor="background_image_select"> Select Background Image: </label>
            <select name={"background_image_select"} id={"background_image_select"} value={backgroundImageString} onChange={e => handleBackgroundChange(e.target.value)} >
                {backgroundImageArray.map(function(val: any, index: any) {
                    return (
                        <option key={val} value={val}> {val}</option>
                    )
                })}
            </select>

            <select name={"image_config_select"} id={"image_config_select"} onChange={(event) => {
                console.log("In change handler for image config select. Value being set:");
                console.log(event.target.value);
                setCurrentImageConfig(getConfigDataById(Number(event.target.value)));
            }}>
                {
                    imageConfigs.filter((element: any, index: any) => element['background_image'] === backgroundImageString).map(function (val: any, index: any) {

                        if (index===0) {
                            //TODO: This is deprecated and this block should be removed;
                            // this should happen when data is loaded not when this elemnent is rendered.
                            //console.log("Setting current image conifg");
                            //setCurrentImageConfig(val);
                        }
                        return(
                            <option key={val['id']} value={val['id']}> {val['speaker_name']} - {val['speaker_company_name']}: {val['background_image']} </option>
                            )

                    })
                }
            </select>
            <button id={"load_image_config"} name={"load_image_config"} onClick={e => handleConfigLoading(e)}>Load</button>
            <button id={"save_image_config"} name={"save_image_config"} onClick={e => handleConfigSaving(e)}>Save</button>
            <br/><br/>
            <button id={"new_image_config"} name={"new_image_config"} onClick={e => handleNewConfig(e)}>New Config (resets form to defaults)</button>

            <br/><br/>
            <label> Brand Name </label>
            <input type="text" name="brand_name" value={brandName} onChange={e => setBrandName(e.target.value)}/>
            <label> Conference Title (Bottom Left)</label>
            <input type="text" name="conference_title" value={conferenceTitle} onChange={e => setConferenceTitle(e.target.value)}/>
            <label> Talk Date (Bottom Right) </label>
            <input type="text" name="talk_date" value={dateString} onChange={e => setDateString(e.target.value)}/>
            <label> Talk Title <br/>(automatically uppercased, must be entered lowercase to support hyphenation)</label>
            <input type="text" name="talk_title" value={talkTitle} onChange={e => setTalkTitle(e.target.value)}/>

            <label> Speaker Name </label>
            <input type="text" name="speaker_name" value={speakerName} onChange={e => setSpeakerName(e.target.value)}/>
            <label> Speaker Title </label>
            <input type="text" name="speaker_title" value={speakerTitle} onChange={e => setSpeakerTitle(e.target.value)}/>


            <label> Speaker Image: </label>
            <input type={"file"} onChange={e => setSpeakerImage(e.target.files?.[0])} />
            <label> Speaker Company Name: </label>
            <input type="text" name="speaker_company_name" value={speakerCompanyName} onChange={e => setSpeakerCompanyName(e.target.value)} />
            <br />
            <hr />
            <h2> Layout & Styling Controls (optional) </h2>

            <label> Brand Name font size</label>
            <input type={"text"} name="brandSize" value={brandNameFontSize} onChange={e => updateCustomStyles('brandNameStyles', 'fontSize', e.target.value)} />
            <label> Brand Name padding top</label>
            <input type={"text"} name="brandTop" value={brandNamePaddingTop} onChange={e => updateCustomStyles('brandNameStyles', 'paddingTop', e.target.value)} />
            <label> Brand Name weight</label>
            <input type={"text"} name="brandWeight" value={brandNameWeight} onChange={e => updateCustomStyles('brandNameStyles', 'fontWeight', e.target.value)} />


            <label> Date and time font size</label>
            <input type={"text"} name="dateSize" value={dateTimeFontSize} onChange={e => updateCustomStyles('dateStringStyles', 'fontSize', e.target.value)} />
            <label> Date and time bottom distance</label>
            <input type={"text"} name="dateBottom" value={dateTimeBottomDistance} onChange={e => updateCustomStyles('dateStringStyles', 'bottom', e.target.value)} />
            <label> Date and time weight</label>
            <input type={"text"} name="dateWeight" value={dateTimeWeight} onChange={e => updateCustomStyles('dateStringStyles', 'fontWeight', e.target.value)} />


            <label> Talk Title font size</label>
            <input type={"text"} name="titleSize" value={talkTitleFontSize} onChange={e => updateCustomStyles('talkTitleStyles', 'fontSize', e.target.value)} />
            <label> Talk Title top margin</label>
            <input type={"text"} name="titleTop" value={talkTitleTopMargin} onChange={e => updateCustomStyles('talkTitleStyles', 'marginTop', e.target.value)} />
            <label> Talk Title weight</label>
            <input type={"text"} name="titleWeight" value={talkTitleWeight} onChange={e => updateCustomStyles('talkTitleStyles', 'fontWeight', e.target.value)} />
            <label> Talk Title width</label>
            <input type={"text"} name="titleWidth" value={talkTitleWidth} onChange={e => updateCustomStyles('talkTitleStyles', 'minWidth', e.target.value)} />


            <label> Speaker Name font size</label>
            <input type={"text"} name="nameSize" value={speakerNameFontSize} onChange={e => updateCustomStyles('nameStyles', 'fontSize', e.target.value)} />
            <label> Speaker Name top distance</label>
            <input type={"text"} name="nameTop" value={speakerNameTopDistance} onChange={e => updateCustomStyles('nameStyles', 'top', e.target.value)} />
            <label> Speaker Name weight</label>
            <input type={"text"} name="nameWeight" value={speakerNameWeight} onChange={e => updateCustomStyles('nameStyles', 'fontWeight', e.target.value)} />


            <label> Speaker Title font size</label>
            <input type={"text"} name="speakerTitleSize" value={speakerTitleFontSize} onChange={e => updateCustomStyles('speakerTitleStyles', 'fontSize', e.target.value)} />
            <label> Speaker Title top distance</label>
            <input type={"text"} name="speakerTitleTop" value={speakerTitleTopDistance} onChange={e => updateCustomStyles('speakerTitleStyles', 'top', e.target.value)} />
            <label> Speaker Title left padding </label>
            <input type={"text"} name="speakerPaddingLeft" value={speakerTitleLeftPadding} onChange={e => updateCustomStyles('speakerTitleStyles', 'paddingLeft', e.target.value)} />

            <label> Speaker Title weight</label>
            <input type={"text"} name="speakerTitleWeight" value={speakerTitleWeight} onChange={e => updateCustomStyles('speakerTitleStyles', 'fontWeight', e.target.value)} />

            <label> Speaker Company Name font size</label>
            <input type={"text"} name="speakerCompanyNameSize" value={speakerCompanyNameFontSize} onChange={e => updateCustomStyles('speakerCompanyNameStyles', 'fontSize', e.target.value)} />
            <label> Speaker Company Name top distance</label>
            <input type={"text"} name="speakerCompanyNameTop" value={speakerCompanyNameTopDistance} onChange={e => updateCustomStyles('speakerCompanyNameStyles', 'top', e.target.value)} />
            <label> Speaker Company Name left padding </label>
            <input type={"text"} name="speakerPaddingLeft" value={speakerCompanyNameLeftPadding} onChange={e => updateCustomStyles('speakerCompanyNameStyles', 'paddingLeft', e.target.value)} />

            <label> Speaker Company Name weight</label>
            <input type={"text"} name="speakerCompanyNameWeight" value={speakerCompanyNameWeight} onChange={e => updateCustomStyles('speakerCompanyNameStyles', 'fontWeight', e.target.value)} />


            <label> Image Width</label>
            <input type={"text"} name="Image Width" value={imageWidth} onChange={e => updateCustomStyles('imageContainerStyles', 'width', e.target.value)} />

            <label> Image Height</label>
            <input type={"text"} name="Image Height" value={imageHeight} onChange={e => updateCustomStyles('imageContainerStyles', 'height', e.target.value)} />

            <label> Image Margin Top</label>
            <input type={"text"} name="Image Top" value={imageMarginTop} onChange={e => updateCustomStyles('imageContainerStyles', 'top', e.target.value)} />

            <label> Image Margin Right</label>
            <input type={"text"} name="Image Right" value={imageMarginRight} onChange={e => updateCustomStyles('imageContainerStyles', 'right', e.target.value)} />

        </form>
    )
}

export default InputForm;