import React from 'react';
import domtoimage from 'dom-to-image';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import ky from 'ky';
import update from 'immutability-helper';

import './ImageGenerator.css';
import InputForm from "./InputForm";
import apiClient from "./services/api";

const backgroundImageArray = [
    //'url("/Whats Next 2020.png")',
    //'url("/Finance Summit.png")',
    'url("/Healthcare Summit.png")',
    'url("/Retail Summit.png")',
    //'url("/Cybersecurity Summit.png")',
    'url("/Ai4 2021.png")',
    'url("/RETCON 2021 Multifamily.png")',
    'url("/RETCON 2021 Office.png")',
    'url("/RETCON 2021 Build.png")',
    'url("/BTF_2021_CX_v3.jpg")',
    'url("/BTF_2021_Risk_v2.png")',
    //'url("/RETCON 2020.jpg")',
];




const  ImageGenerator = (props: { loggedIn: boolean; }) => {
    const [imageConfigs, setImageConfigs] = React.useState([]);
    const [currentImageConfig, setCurrentImageConfig] = React.useState('');

    function loadImageConfigsFromServer(firstRun= false) {
        apiClient.get('/api/image-configs')

            .then(response => {
                console.log(response);
                setImageConfigs(response.data);
                console.log("boutta set firstpass default config");
                response.data.filter((element: any, index: any) => element['background_image'] === backgroundImageString).map(function (val: any, index: any) {
                    console.log("IN firstpass loops")
                    if (index===0 && firstRun === true) {
                        console.log("Setting current image conifg");
                        setCurrentImageConfig(val);
                        generatePreviewImage();
                    }
                })
                //TODO : load button shold start disabled and be re-enabled here.

            })

            .catch(error => console.error(error));
    }

    React.useEffect(() => {

        if (props.loggedIn) {
            console.log("Logged in!");
           loadImageConfigsFromServer(true);

        }
        else {
            console.log("Not logged in!");
        }

    }, [props.loggedIn]);

    const [brandName, setBrandName] = React.useState<string>('Ai4');
    const [dateString, setDateString] = React.useState<string>('May 5-6 2021');
    //const [timeString, setTimeString] = React.useState<string>('H:MMPM ET');
    const [talkTitle, setTalkTitle] = React.useState<string>('Talk Title');
    const [speakerName, setSpeakerName] = React.useState<string>('Speaker Name');
    const [speakerTitle, setSpeakerTitle] = React.useState<string>('Speaker Title');
    const [companyName, setCompanyName] = React.useState<string>('Speaker Company');
    const [conferenceTitle, setConferenceTitle] = React.useState<string>("Ai4 2021 Healthcare Summit");
    // Todo: set type here properly
    const [speakerImage, setSpeakerImage] = React.useState<any>('');
    const [speakerCompanyName, setSpeakerCompanyName] = React.useState<any>('Speaker Company Name');
    const [previewImage, setPreviewImage] = React.useState<any>('');

    const [backgroundImageString, setBackgroundImageString] = React.useState<any>(backgroundImageArray[0])

    // vv depr vv
    const [customStyles, setCustomStyles] = React.useState<any>('')

    const [brandNameFontSize, setBrandNameFontSize] = React.useState('172px')
    const [brandNamePaddingTop, setBrandNamePaddingTop] = React.useState('172px')
    const [brandNameWeight, setBrandNameWeight] = React.useState(700)
    const [dateTimeFontSize, setDateTimeFontSize] = React.useState('127px')
    const [dateTimeBottomDistance, setDateTimeBottomDistance] = React.useState('50px')
    const [dateTimeWeight, setDateTimeWeight] = React.useState('900')
    const [talkTitleFontSize, setTalkTitleFontSize] = React.useState('150px')
    const [talkTitleTopMargin, setTalkTitleTopMargin] = React.useState('70px')
    const [talkTitleWeight, setTalkTitleWeight] = React.useState(800)
    const [talkTitleWidth, setTalkTitleWidth] = React.useState('51%')
    const [speakerNameFontSize, setSpeakerNameFontSize] = React.useState('175px')
    const [speakerNameTopDistance, setSpeakerNameTopDistance] = React.useState('1100px')
    const [speakerNameWeight, setSpeakerNameWeight] = React.useState(900)
    const [speakerTitleFontSize, setSpeakerTitleFontSize] = React.useState('130px')
    const [speakerTitleTopDistance, setSpeakerTitleTopDistance] = React.useState('1288px')
    const [speakerTitleLeftPadding, setSpeakerTitleLeftPadding] = React.useState('0px')
    const [speakerTitleWeight, setSpeakerTitleWeight] = React.useState(400)
    const [speakerCompanyNameFontSize, setSpeakerCompanyNameFontSize] = React.useState('130px')
    const [speakerCompanyNameTopDistance, setSpeakerCompanyNameTopDistance] = React.useState('1560px')
    const [speakerCompanyNameLeftPadding, setSpeakerCompanyNameLeftPadding] = React.useState('0px')
    const [speakerCompanyNameWeight, setSpeakerCompanyNameWeight] = React.useState(400)

    const [imageWidth, setImageWidth] = React.useState<any>('1272px')
    const [imageHeight, setImageHeight] = React.useState<any>('1272px')
    const [imageMarginTop, setImageMarginTop] = React.useState<any>('365px')
    const [imageMarginRight, setImageMarginRight] = React.useState<any>('181px')


    const customStylesObjects = {
        brandNameStyles: {
           fontSize: brandNameFontSize,
           fontWeight: brandNameWeight,
           textAlign: 'left',
            color: 'white',
            marginLeft: '125px',
            paddingTop: brandNamePaddingTop,
            marginTop: '0px',
            marginBottom: '0px',
            paddingBottom: '0px',

        } as React.CSSProperties ,
        dateStringStyles: {
             fontSize: dateTimeFontSize,
            paddingTop: '0px',
            float: 'right',
            paddingRight: '125px',
            textTransform: 'uppercase',
            position: 'absolute',
            bottom: dateTimeBottomDistance,
            right: '25px',
            fontWeight: dateTimeWeight
         } as React.CSSProperties,
        conferenceTitleStyles: {
            fontSize: '127px',
            paddingTop: '0px',
            float: 'right',
            paddingRight: '50px',
            position: 'absolute',
            bottom: '50px',
            left: '25px',
            fontWeight: 900,
            minWidth: '3000px'
        } as React.CSSProperties,
        talkTitleStyles: {
            marginTop: talkTitleTopMargin,
            fontSize: talkTitleFontSize,
            lineHeight: '150px',
            fontWeight: talkTitleWeight,
            minWidth: talkTitleWidth,
            maxWidth: '53%',
        } as React.CSSProperties,
        nameStyles: {
            marginTop: '0px',
            top: speakerNameTopDistance,
            fontSize: speakerNameFontSize,
            lineHeight: '150px',
            fontWeight: speakerNameWeight,
            position: 'absolute',
            minWidth: '60%'
        } as React.CSSProperties,

        speakerTitleStyles: {
            marginTop: '0px',
            paddingLeft: speakerTitleLeftPadding,
            top: speakerTitleTopDistance,
            position: 'absolute',
            fontSize: speakerTitleFontSize,
            lineHeight: '110px',
            fontWeight: speakerTitleWeight,
            minWidth: '40%',
        } as React.CSSProperties,
        speakerCompanyNameStyles: {
            marginTop: '0px',
            paddingLeft: speakerCompanyNameLeftPadding,
            top: speakerCompanyNameTopDistance,
            position: 'absolute',
            fontSize: speakerCompanyNameFontSize,
            lineHeight: '90px',
            fontWeight: speakerCompanyNameWeight,
            minWidth: '40%',
        } as React.CSSProperties,
        companyNameStyles: {
            marginTop: '57px',
            fontSize: '103px',
            lineHeight: '90px',
        } as React.CSSProperties,
        companyLogoStyles: {
            width: 'auto',
            position: 'absolute',
            marginTop: '0px',
            top: '1560px',
            left: '2px',
        } as React.CSSProperties,
        imageContainerStyles: {
            width: imageWidth,
            height: imageHeight,
            borderRadius: '100%',
            overflow: 'hidden',
            position: 'absolute',
            top: imageMarginTop,
            right: imageMarginRight
        } as React.CSSProperties,
    } as React.CSSProperties
   /* const getSpeakerImageAsUrl = function (imageEvent: Event) {
        if (imageEvent.target.files[0]) {
            const reader = new i
        }
    }*/

    function exportPngImage() {
        const node = document.getElementById('imageRootNode');

        try {
            domtoimage.toBlob(node!!)
                .then(function (blob) {
                    saveAs(blob, 'image.png');
                })
        }
        catch (e) {
            alert("There's been an internal error - please contact the administrator for more information.");
        }
    }

    function exportJpegImage() {
        const node = document.getElementById('imageRootNode');

        domtoimage.toJpeg(node!!, {quality: 0.75})
            .then(function(dataUrl) {
                let link = document.createElement('a');
                link.download = 'ai4-promo-image.jpeg';
                link.href = dataUrl;
                link.click();
            });
    }

    function executeImageGeneration(node: HTMLElement | null) {
        try {
            htmlToImage.toPng(node!!)
                .then(function (dataUrl) {
                    setPreviewImage(dataUrl)
                })
        }
        catch (e) {
            alert("There's been an internal error - please contact the administrator for more information.");
        }
    }
    function generatePreviewImage() {

        const node = document.getElementById('imageRootNode');
        executeImageGeneration(node);

    }

    function generatePreviewImageWithDelay() {

        const node = document.getElementById('imageRootNode');
        window.setTimeout(function() {
            executeImageGeneration(node);
        }, 700)

    }

    function setCompanyImageAndRegenerate(file: any) {

        //generatePreviewImage(true)
    }

    function getUrlStringOrEmptyString(image:any, imageName: string): string {

        if (image) {
            if (typeof(image) === 'string') {
                // if the image is already a string, return it so it can be loaded directly
                // (when loaded from API we get a URL for a persisted image;
                // otherwise we get the image object directly from the upload request)
                return image;
            }
            return URL.createObjectURL(image);
        }

        else {

            if (imageName === 'speakerImage') {
                return('1535.png');
            }
            else {
                return('1200x200.png')
            }

        }
    }

    function convertCssStringToReactObject(str: string): object {

        let reactObject = {};
        let stringMatcher: Array<string>;
        stringMatcher = str.match(/[^\s:;/+g]/) || [];

        while (stringMatcher.length) {
            let index: string | undefined = stringMatcher.shift();
            if (index === undefined) {
                throw Error("Invalid CSS");
            }
            reactObject[index] = stringMatcher.shift() || '';
        }

        return reactObject;
    }

    function updateCustomStyles(key: string, property: string, value: string) {
        const styleMappings = {

            'brandNameStyles': {
                'fontSize': setBrandNameFontSize,
                'fontWeight': setBrandNameWeight,
                'paddingTop': setBrandNamePaddingTop
            },
            'dateStringStyles': {
                'fontSize': setDateTimeFontSize,
                'bottom': setDateTimeBottomDistance,
                'fontWeight': setDateTimeWeight,
            },
            'talkTitleStyles': {
                'fontWeight': setTalkTitleWeight,
                'marginTop': setTalkTitleTopMargin,
                'fontSize': setTalkTitleFontSize,
                'minWidth': setTalkTitleWidth,
            },
            'nameStyles': {
                'top': setSpeakerNameTopDistance,
                'fontSize': setSpeakerNameFontSize,
                'fontWeight': setSpeakerNameWeight,
            },
            'speakerTitleStyles': {
                'paddingLeft': setSpeakerTitleLeftPadding,
                'top': setSpeakerTitleTopDistance,
                'fontSize': setSpeakerTitleFontSize,
                'fontWeight': setSpeakerTitleWeight,
            },
            'speakerCompanyNameStyles': {
                'paddingLeft': setSpeakerCompanyNameLeftPadding,
                'top': setSpeakerCompanyNameTopDistance,
                'fontSize': setSpeakerCompanyNameFontSize,
                'fontWeight': setSpeakerCompanyNameWeight,
            },
            'imageContainerStyles': {
                'width': setImageWidth,
                'height': setImageHeight,
                'top': setImageMarginTop,
                'right': setImageMarginRight
            }
        }

        console.log("In updatecustomstyles, current styles: ")
        console.log(customStylesObjects);
        console.log("Updating with key: "+key+"  property: "+property+"  value: "+value);
        try {
            styleMappings[key][property](value);
            console.log("updated values")
            return
        }
        catch (TypeError) {
            console.log("Unmapped key, using legacy update method");
            return
        }
        // create deep copy of value
        let stateCopy = JSON.parse(JSON.stringify(customStylesObjects));

        // newValue[key][property] = value


        let newValue = update(stateCopy[key], {
            [property]: {$set: value}
        });
        let newState = update(stateCopy, {
            [key]: {
                $set: newValue
            }
        });
        console.log("New state:");
        console.log(newState);

    }


    if (!props.loggedIn) {
        return (
            <div> You are not authenticated - please login to continue. </div>
        )
    }
    return (
        <div className={"image-generator-wrapper"} style={customStylesObjects}>
            <div className={"form-wrapper"} style={{minHeight: '2500px', marginBottom: '50px', marginTop: '50px'}}>
                <img src={previewImage} style={{width: '937.5px', height: '525px', float: "left"}}/>

                <InputForm
                    imageConfigs={imageConfigs}
                    currentImageConfig={currentImageConfig}
                    setCurrentImageConfig={setCurrentImageConfig}
                    brandName={brandName}
                    setBrandName={setBrandName}
                    dateString={dateString}
                    setDateString={setDateString}
                   // timeString={timeString}
                    //setTimeString={setTimeString}
                    talkTitle={talkTitle}
                    setTalkTitle={setTalkTitle}
                    speakerName={speakerName}
                    setSpeakerName={setSpeakerName}
                    speakerTitle={speakerTitle}
                    setSpeakerTitle={setSpeakerTitle}
                    companyName={companyName}
                    setCompanyName={setCompanyName}
                    conferenceTitle={conferenceTitle}
                    setConferenceTitle={setConferenceTitle}
                    speakerImage={speakerImage}
                    setSpeakerImage={setSpeakerImage}
                    speakerCompanyName={speakerCompanyName}
                    setSpeakerCompanyName={setSpeakerCompanyName}
                    generatePreviewImage={generatePreviewImage}
                    customStyles={customStyles}
                    customStylesObjects={customStylesObjects}
                    updateCustomStyles={updateCustomStyles}
                    backgroundImageString={backgroundImageString}
                    setBackgroundImageString={setBackgroundImageString}
                    backgroundImageArray={backgroundImageArray}
                    loadImageConfigsFromServer={loadImageConfigsFromServer}

                    brandNameFontSize={brandNameFontSize}
                    brandNamePaddingTop={brandNamePaddingTop}
                    brandNameWeight={brandNameWeight}
                    dateTimeFontSize={dateTimeFontSize}
                    dateTimeBottomDistance={dateTimeBottomDistance}
                    dateTimeWeight={dateTimeWeight}
                    talkTitleFontSize={talkTitleFontSize}
                    talkTitleTopMargin={talkTitleTopMargin}
                    talkTitleWeight={talkTitleWeight}
                    talkTitleWidth={talkTitleWidth}
                    speakerNameFontSize={speakerNameFontSize}
                    speakerNameTopDistance={speakerNameTopDistance}
                    speakerNameWeight={speakerNameWeight}
                    speakerTitleFontSize={speakerTitleFontSize}
                    speakerTitleTopDistance={speakerTitleTopDistance}
                    speakerTitleLeftPadding={speakerTitleLeftPadding}
                    speakerTitleWeight={speakerTitleWeight}
                    speakerCompanyNameFontSize={speakerCompanyNameFontSize}
                    speakerCompanyNameTopDistance={speakerCompanyNameTopDistance}
                    speakerCompanyNameLeftPadding={speakerCompanyNameLeftPadding}
                    speakerCompanyNameWeight={speakerCompanyNameWeight}
                    imageWidth={imageWidth}
                    imageHeight={imageHeight}
                    imageMarginRight={imageMarginRight}
                    imageMarginTop={imageMarginTop}


                />
                <br/>
                <p className={"downloadDescription"}>(high quality .png, potentially large filesize)</p>
                <button onClick={() => exportPngImage} style={{marginTop: '20px', float: 'left', clear: 'left'}}> Download .png </button>
                <p className={"downloadDescription"}>(compressed .jpg with much smaller filesize)</p>
                <button onClick={() => exportJpegImage} style={{marginTop: '20px', float: 'left', clear: 'left'}}> Download .jpeg </button>
            </div>


            <div id={'imageRootNode'} /*ref={this.imageNodeRef}*/ style={{backgroundImage: backgroundImageString, width: '3750px', height: '2100px'}} onLoad={generatePreviewImageWithDelay}>
                <h2 className={"brandName"} style={customStylesObjects['brandNameStyles']}> {brandName} </h2>

                <p className={"talkTitle"} lang={"en"} style={customStylesObjects['talkTitleStyles']}> {talkTitle} </p>
                <p className={"speakerName"} style={customStylesObjects['nameStyles']}> {speakerName} </p>
                <p className={"speakerTitle"} style={customStylesObjects['speakerTitleStyles']}> {speakerTitle} </p>
                <p className={'speakerCompanyName'} style={customStylesObjects['speakerCompanyNameStyles']}  > {speakerCompanyName} </p>

                <div className={"imageContainer"} style={customStylesObjects['imageContainerStyles']}> <img crossOrigin={'use-credentials'} style={{width: customStylesObjects['imageContainerStyles']['width'], height: customStylesObjects['imageContainerStyles']['height']}} className={'speakerImage'} src={getUrlStringOrEmptyString(speakerImage, 'speakerImage') || '1535.png'} /> </div>
                <p className={"conferenceTitle"} style={customStylesObjects['conferenceTitleStyles']}> {conferenceTitle} </p>
                <p className={"dateString"} style={customStylesObjects['dateStringStyles']}> {dateString} <span className={"invisibleHackSpan"}> a </span></p>
            </div>
        </div>

    )
}

export default ImageGenerator


